import React, { Component, Fragment } from 'react';
import './assets/css/main.css';

class App extends Component {
  render() {
    return (
      <Fragment>
      <nav class="navbar sticky-top navbar-dark bg-blue">
        <a class="navbar-brand" href="/">
          <img src="/img/logo.png" className="logo" />
          PetPal Support
        </a>
        <div className="float-right">
          <a className="btn btn-primary btn-raised" href="https://rescue.petpalmanager.com">Login</a>
        </div>
      </nav>
      <div className="container mt-5 mb-5">
        <div className="card">
          <div className="card-body">
          <nav>
    					<div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
    						<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-pprm" aria-selected="true">PetPal Rescue Manager</a>
    						<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-isavedapet" aria-selected="false">isavedapet.com</a>
    						<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-petshow" aria-selected="false">Pet Show</a>
    					</div>
    				</nav>
    				<div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
    					<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-pprm-tab">
    						<p><img src="/img/logo.png" className="float-left logo-icon mr-2" /> <a href="http://petpalmanager.com">PetPal Rescue Manager</a> is a complete online animal rescue management solution for animal rescues and shelters. With over 60 different record keeping modules available, PPRM help you keep track of all your pets, adoption, finances, teams, events, volunteers, fundraising, and much more.</p>
                <p>For technical issues and bugs, please login into <a href="https://rescue.petpalmanager.com">PetPal Rescue Manager</a> and submit a ticket so the we are aware of issues and can fix them.</p>
                <p>For questions and training requests, please send a detailed email to <a href="mailto:info@petpalmanager.com">info@petpalmanager.com</a> and a representative will get back to you with answers or set up a time for training you and your staff.</p>
    					</div>
    					<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-isavedapet-tab">
    						<a href="https://isavedapet.com">isavedapet.com</a> is a companion web site for <a href="http://petpalmanager.com">PetPal Rescue Manager</a> that allow you to post adoption stories to a platform when they are shareable for the adopters.
                <p>If you have issues with <a href="https://isavedapet.com">isavedapet.com</a>  not posting stories or if you are not able to remove stories from the platform, please log into <a href="https://rescue.petpalmanager.com">PetPal Rescue Manager</a> and submit a ticket in the bug system
                so we can take care of the issue.</p>
                <p>If you have questions regarding isavedapet.com, please send us an email at <a href="mailto:info@petpalmanager.com">info@petpalmanager.com</a>.</p>
    					</div>
    					<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-petshow-tab">
              <p><img src="/img/Icon-40@2x.png" className="float-left mr-3 b-3" />PetPal PetShow is a companion mobile application (iOS and Android) for <a href="http://petpalmanager.com">PetPal Rescue Manager</a> that allow you display all your pets available for adoption from anywhere. Whether you are out and about or at a pet show, you can show all the pets that are not with you
              and allow for potential adopters to submit a 'Interested Form' which is saved to the <a href="https://rescue.petpalmanager.com">PetPal Rescue Manager</a> application which you can then follow up with them at a later time.</p>
              <p>For technical issues and bugs with the mobile application, please login into <a href="https://rescue.petpalmanager.com">PetPal Rescue Manager</a> and submit a ticket so the we are aware of issues and can fix them. Please include which version (iOS or Android) and what mobile device you are currently using when you experienced the issue.</p>
              <p>For questions regarding the mobile applications, please send a detailed email to <a href="mailto:info@petpalmanager.com">info@petpalmanager.com</a> and a representative will get back to you with answers.</p>
    					</div>

    				</div>
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-body">
            <p align="center" className=""><h5><a href="http://petpalmanager.com">Not a member yet? Check out all the features of PetPal Rescue Manager and join today!</a></h5></p>
          </div>
        </div>

      </div>
      </Fragment>
    );
  }
}

export default App;
